<template>
  <v-navigation-drawer
    v-model="showModal"
    width="700"
    persistant
    class="edit-project-sidebar"
    right
  >
    <v-card
      outlined
      color="#fff"
    >
      <br>
      <h2 class="title-style">
        <v-icon
          class="icon-style"
          left
        >
          mdi-pencil-outline
        </v-icon>Update Project
      </h2>
      <p class="sub-heading-creation">
        Please fill in the required fields marked with an asterisk (*) and click the 'Update' button to save your changes to the project.
      </p>
      <v-icon
        class="close-btn"
        @click="showModal = false"
      >
        mdi-close
      </v-icon>
      <v-container fluid>
        <v-alert
          v-if="disableEditButton"
          text
          prominent
          icon="mdi-information-symbol"
          color="#01579B"
          border="left"
          class="authorization-alert"
        >
          EzyBuild does not support editing imported data. Please make changes to your Autodesk account and resync.
        </v-alert>
      </v-container>
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <v-container>
          <v-card-text class="text-body-1 text-left">
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="projectName"
                  label="Project Name*"
                  dense
                  outlined
                  :rules="[(v) => !!v || 'This field is required']"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="projectNumber"
                  label="Project Number*"
                  dense
                  outlined
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="projectType"
                  :items="projectTypes"
                  label="Type*"
                  :rules="[(v) => !!v || 'This field is required']"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12">
                <address-form
                  ref="addressForm"
                  class="address-form"
                  @addressEntered="assiginAddress($event)"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-dialog
                  ref="startDialog"
                  v-model="startDateDialog"
                  :return-value.sync="projectStartDate"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="projectStartDate"
                      label="Project Start Date*"
                      :rules="[(v) => !!v || 'This field is required']"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="projectStartDate"
                    scrollable
                    color="#37474F"
                    :min="minStartDate"
                    @input="$refs.startDialog.save(projectStartDate)"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="startDateDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-dialog
                  ref="endDialog"
                  v-model="endDateDialog"
                  :return-value.sync="projectEndDate"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="projectEndDate"
                      label="Project End Date*"
                      :rules="[(v) => !!v || 'This field is required']"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="projectEndDate"
                    scrollable
                    color="#37474F"
                    :min="minEndDate"
                    @input="$refs.endDialog.save(projectEndDate)"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="endDateDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="projectCurrency"
                  class="field-style"
                  :items="formattedCurrenciesList"
                  :rules="[(v) => !!v || 'Feild is required']"
                  item-value="text"
                  item-text="text"
                  label="Currency*"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="projectValue"
                  class="field-style"
                  label="Project Value"
                  hint="Estimated project cost"
                  persistent-hint
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="projectTimeZone"
                  class="field-style"
                  :items="timeZonesByName"
                  label="Time Zone"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="projectStatus"
                  class="field-style"
                  :items="statusList"
                  label="Project Status*"
                  :rules="[(v) => !!v || 'This field is required']"
                  single-line
                  item-value="id"
                  item-text="name"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="#000"
              :disabled="disableEditButton"
              @click="updateProjectDetails"
            >
              update
            </v-btn>
            <v-btn
              class="mt-2"
              color="#aeaeae"
              @click="showModal = false"
            >
              cancel
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-navigation-drawer>
</template>

<script>
import Constants from 'src/constants';
import AddressForm from 'src/views/dashboard/component/AddressForm';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import moment from 'moment';

export default {
  name: 'EditProject',
  components: {
    'centre-spinner': spinner,
    'address-form': AddressForm,
  },
  props: {
    projectId: {
        type: String,
        required: true,
    },
  },
  data () {
    return {
      showModal: true,
      isFormValid: false,
      projectStartDate: '',
      projectEndDate: '',
      startDateDialog: false,
      endDateDialog: false,
      minStartDate: moment().format('YYYY-MM-DD'),
      statusList: [
      {
        name: 'Active',
        id: 'active',
      },
      {
        name: 'Pending',
        id: 'pending',
      },
      {
        name: 'Completed',
        id: 'completed',
      },
      ],
      projectName: '',
      projectNumber: '',
      projectType: '',
      projectCurrency: '',
      projectValue: '',
      projectStatus: '',
      projectTimeZone: '',
      projectAddress: {},
      loading: false,
      disableEditButton: false,
    };
  },
 computed: {
    timeZones () {
      return this.$store.getters['userprofile/getTimeZones'];
    },
    timeZonesByName () {
      return Object.keys(this.timeZones).map((key) => ({ text: key, value: this.timeZones[key] }));
    },
    projectTypes () {
      return this.$store.getters['projects/getProjectTypes'];
    },
    currencyList () {
      return this.$store.getters['userprofile/getCurrencyList'];
    },
    formattedCurrenciesList () {
      return Object.keys(this.currencyList).map((key) => ({ text: key, value: this.currencyList[key] }));
    },
    minEndDate () {
      return moment(this.projectStartDate).format('YYYY-MM-DD');
    },
    projectDetails () {
      return this.$store.getters['projects/getProjectDetails'];
    },
  },
  watch: {
    showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
  },
  async mounted () {
    this.loading = true;
    await this.getAllTimeZone();
    await this.getProjectType();
    await this.getCurrencyList();
    await this.getProjectDetails();
    this.loading = false;
  },
  methods: {
    async getAllTimeZone () {
      await this.$store.dispatch('userprofile/fetchTimeZones');
    },
    async getProjectType () {
      await this.$store.dispatch('projects/fetchProjectTypes');
    },
    async getCurrencyList () {
      await this.$store.dispatch('userprofile/fetchCurrency');
    },
    async getProjectDetails () {
      await this.$store.dispatch('projects/fetchProjectDetails', {
        projectId: this.projectId,
      }).then(response => {
        this.projectName = this.projectDetails.name;
        this.projectValue = this.projectDetails.project_value;
        this.projectNumber = this.projectDetails.project_number;
        this.projectType = this.projectDetails.type;
        this.projectCurrency = this.projectDetails.currency;
        this.projectStatus = this.projectDetails.status;
        this.projectStartDate = this.projectDetails.start_date;
        this.projectEndDate = this.projectDetails.end_date;
        this.projectTimeZone = this.projectDetails.timezone;
        this.$refs.addressForm.fillData(this.projectDetails.address);
        if (this.projectDetails.database === false) {
            this.disableEditButton = true;
            return;
          }
          this.disableEditButton = false;
      });
    },
    assiginAddress (address) {
        this.projectAddress = address;
    },
    updateProjectDetails () {
      if (this.$refs.form.validate() === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        this.$refs.addressForm.getAddress();
        this.$store.dispatch('projects/updateProjectDetails', {
          projectId: this.projectId,
          updateDetails: {
            name: this.projectName,
            project_number: this.projectNumber,
            type: this.projectType,
            address: this.projectAddress,
            start_date: this.projectStartDate,
            end_date: this.projectEndDate,
            currency: this.projectCurrency,
            project_value: this.projectValue,
            timezone: this.projectTimeZone,
            project_status: this.projectStatus,
          },
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Project details updated successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$emit('project-details-updated');
          this.loading = false;
          this.showModal = false;
        }).catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.title-style {
  color: #37474F;
  margin-left: 21px;
  margin-bottom: 10px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: -10px;
  padding-bottom: 10px;
  padding-top: 10px;
}
.edit-project-sidebar{
  position: absolute !important;
  right: 0;
  top: 0;
  width: 650px;
  z-index: 99;
}
.sub-heading-creation {
  margin-bottom: 0px !important;
  color: #aaa !important;
  margin-left: 21px;
  margin-right: 21px;
}
.text-body-1 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.address-form{
  background-color: #fff !important;
  border-left: 5px solid #000 !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.close-btn{
  position: absolute;
  right: 15px;
  top: 0;
}
</style>
