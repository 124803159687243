<template>
  <v-dialog
    v-model="showModal"
    max-width="500"
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon
          class="icon-style"
          left
        >
          mdi-delete-alert
        </v-icon>Permanent Delete ?
      </h2>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-container>
          <v-card-text class="text-body-1 text-center">
            <p class="my-p-style">
              To confirm delete, type <span class="delete-confirm">permanently delete</span> in the text input field. <br><br>
            </p>
          </v-card-text>
          <v-row
            class="fill-height"
            align-content="center"
            justify="center"
          >
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                v-model="confirmDelete"
                label="Confirm Delete*"
                class="field-style"
                dense
                outlined
                :rules="confirmDeleteRules.concat(typeErrorCheck)"
                single-line
              />
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
              color="red darken-1"
              @click="showModal = false"
            >
              Cancel
            </v-btn>
            <v-spacer />
            <v-btn
              color="#FF3700"
              @click="confirm()"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import Constants from 'src/constants';
  export default {
    name: 'ConfirmDeleteDialog',
    data () {
      return {
        showModal: true,
        valid: false,
        confirmDelete: '',
        confirmDeleteRules: [v => !!v || 'This field is required'],
      };
    },
    computed: {
      typeErrorCheck () {
        return this.confirmDelete === 'permanently delete' || 'Type Error';
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    methods: {
      confirm () {
        if (this.$refs.form.validate() === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.$emit('confirmed');
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-p-style {
  font-size: 18px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: center;
}
.field-style {
  margin-top: -25px;
}
.delete-confirm {
  color: #FF3700;
  font-style: italic;
}
</style>
