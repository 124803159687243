<template>
  <v-dialog
    v-model="showModal"
    max-width="800"
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon
          class="icon-style"
          left
        >
          mdi-hub
        </v-icon>
        Hubs & Projects
      </h2>
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <v-container fluid>
          <p class="my-p-style">
            Select the Projects You Want to Import
          </p>
          <v-alert
            text
            prominent
            icon="mdi-information-symbol"
            color="#01579B"
            border="left"
            class="authorization-alert2"
          >
            EzyBuild only shows Autodesk Construction Cloud and BIM 360 hubs.
          </v-alert>
          <v-alert
            v-if="!isAnyHubsAvailable"
            text
            prominent
            type="warning"
            color="red"
            border="left"
            class="authorization-alert"
          >
            No hubs found. Please check with your administrator.
          </v-alert>
          <div
            class="div-style"
          >
            <v-list
              color="#ECEFF1"
            >
              <v-list-group
                v-for="hub in userHubs"
                :key="hub.hub_id"
                sub-group
                color="black"
                @click="getProjectsFromHubs(hub.hub_id)"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title class="header-style">
                      {{ hub.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item-group
                  multiple
                  class="pl-10"
                >
                  <template v-for="item in projectsFromHubs">
                    <div
                      v-if="hub.hub_id === item.hub_id"
                      :key="item.hub_id"
                      class="list-style"
                      color="black"
                    >
                      <p
                        v-if="!isNewProjectsAvailable(item.projects)"
                        class="no-projects-alert"
                      >
                        No New Projects Found.
                      </p>
                      <v-list-item-content>
                        <template v-for="x in item.projects">
                          <v-list-item-title :key="x.project_id">
                            <v-container
                              v-if="x.record_exist === false"
                              fluid
                              class="checkbox-div"
                            >
                              <input
                                type="checkbox"
                                class="checkbox-style"
                                :checked="isProjectAlreadyChecked(x.project_id)"
                                @change="selectProject(item, x)"
                              >
                              <span class="my-checkbox"> {{ x.project_name }}</span>
                            </v-container>
                          </v-list-item-title>
                        </template>
                      </v-list-item-content>
                    </div>
                  </template>
                </v-list-item-group>
              </v-list-group>
            </v-list>
          </div>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="red darken-3"
              @click="showModal = false"
            >
              cancel
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#FF3700"
              :disabled="!isProjectsSelected"
              @click="syncAndGetAllProjects()"
            >
              Get
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import spinner from 'src/views/dashboard/component/SpinnerCentre';

export default {
  name: 'SelectHubs',
  components: {
    'centre-spinner': spinner,
  },
  data () {
    return {
      showModal: true,
      isFormValid: false,
      loading: false,
      selectedProjects: [],
    };
  },
  computed: {
    userHubs () {
      return this.$store.getters['partners/getUserHubs'];
    },
    isAnyHubsAvailable () {
      if (this.userHubs.length > 0) {
        return true;
      }
      return false;
    },
    projectsFromHubs () {
      return this.$store.getters['partners/getProjectsFromHubs'];
    },
    isProjectsSelected () {
      if (this.selectedProjects.length > 0) {
        return true;
      }
      return false;
    },
  },
  watch: {
    showModal: function (newval, oldval) {
      if (newval === false) {
        this.$emit('closed');
      }
    },
  },
  async mounted () {
    this.$store.dispatch('partners/clearProjectsFromHubsArray');
    await this.getUserHubs();
  },
  methods: {
    async getUserHubs () {
      this.loading = true;
      await this.$store.dispatch('partners/fetchUserHubs').catch(() => {
        this.loading = false;
      });
      this.loading = false;
    },
    async getProjectsFromHubs (hubId) {
      this.loading = true;
      await this.$store.dispatch('partners/fetchProjectsFromHubs', {
        hubId: hubId,
      }).catch(() => {
        this.loading = false;
      });
      this.loading = false;
    },
    isProjectAlreadyChecked (id) {
     if (this.selectedProjects.some(item => item.projectId === id)) {
        return true;
      }
      return false;
    },
    selectProject (hub, project) {
      if (this.selectedProjects.some(item => item.projectId === project.project_id)) {
        const index = this.selectedProjects.findIndex(item => item.projectId === project.project_id);
        this.selectedProjects.splice(index, 1);
        return;
      }
      this.selectedProjects.push({
        projectId: project.project_id,
        hubId: hub.hub_id,
      });
    },
    syncAndGetAllProjects () {
      this.loading = true;
      const projectsList = this.selectedProjects;
      const result = Array.from(new Set(projectsList.map(s => s.hubId)))
      .map(id => {
        return {
          hub_id: id,
          project_id: projectsList.filter(s => s.hubId === id).map(projects => projects.projectId),
        };
      });
      this.loading = false;
      this.$emit('sync-projects', result);
    },
    isNewProjectsAvailable (projects) {
      if (projects.some(item => item.record_exist === false)) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-p-style {
  font-size: 18px;
  font-weight: bold;
  color: #FF3700;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: center;
}
.my-p-style-sub {
  font-size: 16px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.header-style {
    font-size: 16px;
    font-weight: bold;
    color: #37474F;
}
.div-style {
    margin-right: auto;
    margin-left: auto;
}
.list-style {
  margin-right: 40px;
}
@media (max-width: 767px) {
  .v-application--is-ltr .v-list-group--sub-group .v-list-group__items {
    padding-right: 10px;
    padding-left: 10px;
  }
}
.checkbox-div {
  background: #CFD8DC;
  padding-top: 15px;
  padding-bottom: 15px;
}
.my-checkbox {
  padding-left: 10px;
  font-size: 16px;
  color: #37474F;
  font-weight: bold;
}
input[type="checkbox"] {
accent-color: #FF3700
}
.no-projects-alert {
  font-size: 16px;
  margin-top: 20px;
  color: red;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: -10px;
  padding-bottom: 10px;
  padding-top: 10px;
}
.authorization-alert2 {
  font-family: 'Times New Roman', Times, serif;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 16px;
  margin-top: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
}
</style>
